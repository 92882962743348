// BGC

export default {
  settings: {
    disableIframePostMessage: false,
  },
  content: {
    general: {
      clientName: "BGC",
      ctaMask: true,
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          BGC Superannuation Fund
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-6">
          <p>
            <strong>BGC is a large corporate super fund</strong> where all members’ savings are pooled together so that you pay lower fees, which means you have more super savings invested and more money in retirement.
          </p>
          <p>
            The BGC offers you access to <strong>large fund discounts, insurances at discount rates</strong>, plus a varied range of investment choices.
          </p>
          <p>
            Gallagher is here to provide you with all the assistance necessary, and to show you how to take control of your long term savings for you and your family.
          </p>
        </div>
        <div class="col-lg-6">
          <h3>IOOF Employer Super</h3>
          <p>BGC's super fund is powered by IOOF, now Insignia Financial. Follow the links below to find out more about it.</p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <a href='https://microsite.ioof.com.au/employersuper-microsites/bgc' target='_blank'>BGC IOOF Super Website</a>
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <a href='https://www.ioof.com.au/' target='_blank'> IOOF Employer Super Website</a>
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <a href='https://www.ioof.com.au/calculators/risk_profiler/index.html' target='_blank'>IOOF Risk Profiler</a>
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <a href='https://www.ioof.com.au/superannuation/products-and-services/ioof-employer-super/investment-menu' target='_blank'>IOOF Investment Options</a>
            </li>
          </ul>
        </div>
      </div>
    `,
    pdfs: [
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "Join the BGC fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "BGC-New_Employee_Pack-September_2024.pdf",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "IOOF Online - Registration",
        description: "Find out how to register online with IOOF.",
        type: "info",
        url: "IOOF-Registration-form.pdf",
      },
      {
        title: "Product Disclosure Statement",
        description: "In-depth information on your super fund.",
        type: "info",
        url: "IOOF-Employer-Super-PDS-PLA-23705-50670-0323.pdf",
      },
      {
        title: "Finding your Lost Super",
        description:
          "Complete this form to authorise IOOF to search for your super.",
        type: "form",
        url: "IOOF-SuperMatchform.pdf",
      },
      {
        title: "IOOF Forms",
        description: "Member and Employer forms booklet",
        type: "form",
        url: "IES_ES_Forms_Booklet_PLA-19213_(50930)_31032023.pdf",
      },
      {
        title: "IOOF Employer Super Insurance Guide",
        description: "In-depth information on your super fund insurances.",
        type: "info",
        url: "IOOF-Insurance-guide-IOF_03-31032023.pdf",
      },
      {
        title: "Insurance Opt-In",
        description:
          "Do you want to retain your insurance in your super account?",
        type: "form",
        url: "IOOF-Insurance-opt-in-form.pdf",
      },
    ],
    optIn: "IOOF-Insurance-opt-in-form.pdf",
    teamMembers: [
      {
        teamMemberPic: "sharron.jpg",
        name: "Sharron Wharton-Street",
        phoneHref: "0862508493",
        phone: "08 6250 8493",
        email: "Sharron_WhartonStreet@ajg.com.au",
        social: "https://www.linkedin.com/in/sharron-wharton-street-801026161/",
      },
      {
        teamMemberPic: "anthony.png",
        name: "Anthony Warman",
        phoneHref: "0862508308",
        phone: "08 6250 8308",
        email: "anthony_warman@ajg.com.au",
        social: "http://au.linkedin.com/pub/anthony-warman/7b/53a/75a",
      },
      {
        teamMemberPic: "natasha.png",
        name: "Natasha Van",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "super-service@ajg.com.au",
      },
    ],
    footerClientName: "BGC",
  },
  superscore: false,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
